<template>
  <div>
    <van-search
      v-model="queryForm.QDDX_MC"
      shape="round"
      background="#2778F8"
      placeholder="请输入劝导对象"
      @search="onSearch"
      @cancel="onCancel"
    />
    <van-row>
      <van-col v-if="queryForm.Type == 1" span="10">
        <van-field
          readonly
          clickable
          :value="queryForm.Date_OP"
          placeholder="点击选择时间"
          :rules="[{ required: true, message: '请选择时间' }]"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-datetime-picker
            v-model="date"
            type="date"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
      </van-col>
      <van-col span="10">
        <van-field
          readonly
          clickable
          name="picker"
          :value="name"
          placeholder="点击选择状态"
          @click="showStatus = true"
        />
        <van-popup v-model="showStatus" position="bottom">
          <van-picker
            show-toolbar
            :default-index="0"
            value-key="text"
            :columns="statusOptions"
            :colunms-field-names="{ text: 'label' }"
            @cancel="showStatus = false"
            @confirm="onConfirmStatus"
          />
        </van-popup>
      </van-col>
      <van-col :span="4">
        <van-field readonly label="重置" @click="reset" />
      </van-col>
    </van-row>

    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :offset="15"
        @load="onLoad"
      >
        <div>
          <div v-if="flag === true">
            <div
              v-for="(item, i) in list"
              :key="i"
              class="yun_newedition_resume"
            >
              <div class="yun_newedition_resumelist">
                <a @click="toInfo(item)">
                  <div class="yun_newedition_resume_wantjob">
                    <span class="yun_newedition_resume_wantjob_n">
                      劝导对象：{{ item.QDDX_MC }}
                    </span>
                  </div>
                  <div class="user_undergo_box">
                    <div class="user_undergo user_undergo_a">
                      执法类型：{{ item.ZFLB_Name }}
                    </div>
                    <div class="user_undergo user_undergo_a">
                      处理结果：{{ item.CLJG_Name }}
                    </div>
                    <div class="user_undergo user_undergo_a">
                      劝导时间：{{ item.Date_OP }}
                    </div>
                    <div class="user_undergo user_undergo_a">
                      目前状态：
                      <span
                        :style="{
                          color: item.This_Status === 1 ? 'Green' : 'Red',
                        }"
                      >
                        {{ item.This_Status_Name }}
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <van-empty v-else description="暂无劝导记录，请重新查询" />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'
  import Base64 from '@/util/Base64'
  import { Toast } from 'vant'
  import { getAdviseList } from '@/api/workBench/advise/advise'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'Index',
    data() {
      return {
        list: [],
        type: '',
        value: '',
        flag: true,
        loading: false, //是否处于加载状态
        finished: false, //是否已加载完所有数据
        isFinished: false,
        pageCount: '', //总页数
        date: new Date(),
        queryForm: {
          intPageIndex: 1,
          intPageSize: 10,
          Team_ID: 0,
          Cell_ID: 81,
          Shop_ID: 0,
          This_Status: 0,
          Date_OP: '', //登记时间,
          QDDX_MC: '',
          Type: 1, //标识 1默认 2标识从待办任务过来
          Date: moment(new Date()).format('yyyy-MM-DD'),
        },
        isLoading: false,
        showPicker: false,
        showStatus: false,
        name: '',
        statusOptions: [
          {
            text: '请选择状态',
            value: 0,
          },
          {
            text: '进行中',
            value: 1,
          },
          {
            text: '结束',
            value: 2,
          },
        ],
      }
    },
    created() {
      const userInfo = JSON.parse(store.getters['user/userInfo'])
      let teamInfo = JSON.parse(store.getters['user/teamInfo'])
      this.queryForm.Team_ID = teamInfo.Team_ID
      this.queryForm.Cell_ID = teamInfo.Cell_ID
      this.queryForm.Shop_ID =
        this.$route.query.shopID === undefined
          ? 0
          : Base64.decode(this.$route.query.shopID)
      this.queryForm.Type =
        this.$route.query.type === undefined
          ? 1
          : Base64.decode(this.$route.query.type)
      this.fetchData()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'advise',
        '城管-劝导列表',
        'zzdcg.yy.gov.cn/advise'
      )
    },
    methods: {
      //页面动态加载方法
      onLoad() {
        this.loading = true
        //当页面数大于总页数时，停止加载请求数据
        if (this.queryForm.intPageIndex >= this.pageCount) {
          this.loading = false
          this.isFinished = true //数据加载完
        } else {
          this.queryForm.intPageIndex++
          this.fetchData()
        }
      },
      //获取数据
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getAdviseList(this.queryForm)
        Toast.clear()
        if (data.data.length > 0) {
          this.flag = true
          this.pageCount = data.pageCount
          this.list = this.list.concat(data.data)
          this.loading = false
        } else {
          this.flag = false
          this.loading = false //不处于加载状态
          this.isFinished = true //数据加载完，不会再处于加载状态
        }
      },

      //跳转至详情
      toInfo(item) {
        this.$router.push({
          path: 'adviseInfo',
          query: {
            advise_ID: Base64.encode(item.ID),
          },
        })
      },

      onSearch(val) {
        this.list = []
        this.queryForm.intPageIndex = 1
        this.queryForm.QDDX_MC = val
        this.fetchData()
      },
      onCancel() {
        this.list = []
        this.queryForm.intPageIndex = 1
        this.queryForm.QDDX_MC = ''
        this.fetchData()
      },

      onRefresh() {
        this.list = []
        this.queryForm.intPageIndex = 1
        this.fetchData()
        this.isLoading = false
      },

      onConfirm(date) {
        this.list = []
        this.queryForm.Date_OP = moment(date).format('YYYY-MM-DD')
        this.showPicker = false
        this.fetchData()
      },

      onConfirmStatus(value) {
        this.list = []
        this.value = value
        this.name = value.text
        this.queryForm.This_Status = value.value
        this.showStatus = false
        this.fetchData()
      },

      //格式化时间
      reset() {
        this.list = []
        this.queryForm.intPageIndex = 1
        this.queryForm.Date_OP = ''
        this.queryForm.This_Status = 0
        this.name = ''
        this.date = new Date()
        this.queryForm.QDDX_MC = ''
        this.fetchData()
      },
    },
  }
</script>

<style scoped>
  .yun_newedition_resume_wantjob {
    padding-bottom: 0.266666rem;
    font-size: 0.426666rem;
    color: #666;
  }
  .yun_newedition_resume_wantjob_n {
    font-size: 0.426666rem;
    font-weight: bold;
    color: #000;
  }
  .yun_newedition_resumelist a {
    position: relative;
    display: block;
    padding: 0.4rem 0.4rem 0.4rem 0.4rem;
  }
  .new_userlist_p {
    position: relative;
    padding-bottom: 0.266666rem;
    font-size: 0.346666rem;
    color: #333;
  }
  .user_undergo {
    width: 100%;
    height: 0.8rem;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .yun_newedition_resume {
    padding: 0rem 0.32rem 0rem 0.32rem;
  }
  .yun_newedition_resumelist {
    margin-top: 0.266666rem;
    background: #fff;
    border-radius: 0.213333rem;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
    padding-left: 0.666666rem;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../assets/images/workBench/advise/icon.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
</style>
