import request from '@/util/request'

export function getAdviseList(params) {
  return request({
    url: '/Advise.asmx/GetAdviseList',
    method: 'GET',
    params,
  })
}

export function addAdvise(params) {
  return request({
    url: '/Advise.asmx/AddAdvise',
    method: 'get',
    params,
  })
}

export function getAdviseInfo(params) {
  return request({
    url: '/Advise.asmx/GetAdviseInfo',
    method: 'GET',
    params,
  })
}

export function adviseReply(data) {
  return request({
    url: '/Advise.asmx/AdviseReply',
    method: 'post',
    data,
  })
}
